<template>
  <div>
    <div v-if="!isVerifying" class="flex flex-col gap-6">
      <img
        src="~/assets/svg/discord-icon.svg"
        alt="Discord"
        class="w-20 h-20 mx-auto"
      />
      <Typography size="main-xl" class="text-center leading-none text-nowrap">
        Join Discord
      </Typography>
      <Typography size="secondary-book" class="text-center">
        We will ask for permissions to access your Discord user and join our
        server
      </Typography>
      <button
        @click="handleJoinDiscord"
        class="bg-purple text-white px-6 rounded mx-auto flex items-center gap-4"
      >
        <Typography size="main-l">Join Discord</Typography>
        <img
          src="~/assets/svg/discord-icon.svg"
          alt="discord-logo"
          class="w-8 h-8"
        />
      </button>
    </div>
    <div v-if="isVerifying" class="flex flex-col gap-6">
      <div v-if="!isSuccess && attempt < 5" class="flex flex-col gap-6">
        <Typography size="main-xl" class="text-center leading-none text-nowrap">
          Verifying you joined
        </Typography>
        <Typography size="secondary-book" class="text-center">
          Estamos comprobando si te has unido a nuestro servidor de Discord
        </Typography>
        <div role="status" class="mx-auto">
          <svg
            aria-hidden="true"
            class="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-purple"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
        <Typography size="secondary-book" class="text-center">
          Attempt #{{ attempt }}/5 (next in {{ countDown }} seconds)
        </Typography>
      </div>

      <div v-if="attempt >= 5 && !isSuccess" class="flex flex-col gap-6">
        <Typography size="main-xl" class="text-center leading-none text-nowrap">
          We couldn't find you
        </Typography>
        <Typography size="secondary-book" class="text-center">
          We couldn't verify your membership in our Discord server. Please make
          sure you have joined our server and try again.
        </Typography>
        <button
          @click="modalsStore.closeModal"
          class="bg-purple text-white px-8 rounded mx-auto flex items-center gap-4"
        >
          <Typography size="main-l">Close</Typography>
        </button>
      </div>
      <div v-if="isSuccess" class="flex flex-col gap-6">
        <Typography size="main-xl" class="text-center leading-none text-nowrap">
          YOU ARE IN DISCORD
        </Typography>
        <Typography size="secondary-book" class="text-center">
          Thanks for joining our Discord server! You will receive your reward
          shortly.
        </Typography>
        <LucideCheckCircle class="w-24 h-24 mx-auto text-purple" />

        <button
          @click="continueToClaim"
          class="bg-purple text-white px-8 rounded mx-auto flex items-center gap-4"
        >
          <Typography size="main-l">Continue...</Typography>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "~/stores/useModalsStore";
import { useAuthStore } from "~/stores/useAuthStore";

const modalsStore = useModalsStore();
const auth = useAuthStore();

const isVerifying = ref(false);
const isSuccess = ref(false);
const attempt = ref(0);
const countDown = ref(20);

const continueToClaim = () => {
  modalsStore.closeModal();
};

const handleJoinDiscord = async () => {
  checkDiscordUserJoined();
};

async function checkDiscordUserJoined() {
  const url_base = useRuntimeConfig().public.apiLogicUrl;
  const discord_oauth_url = useRuntimeConfig().public.discordOauthUrl;

  const maxAttempts = 5;
  const discordUserId = auth.twitterProfile.directusUser.discord_id;
  console.log(discordUserId);

  while (attempt.value < maxAttempts) {
    attempt.value++;
    try {
      if (discordUserId === null) {
        isVerifying.value = true;
        window.location.href = discord_oauth_url;
      } else {
        const response = await $fetch(
          `${url_base}/discord/hasjoined/${discordUserId}`
        );
        if (response) {
          isSuccess.value = true;
          isVerifying.value = true;
        } else {
          isVerifying.value = true;
          window.location.href = discord_oauth_url;
        }
      }
    } catch (error) {
      console.error("Error en la petición:", error);
    }

    if (attempt.value < maxAttempts) {
      countDown.value = 20;
      const interval = setInterval(() => {
        countDown.value--;
        if (countDown.value === 0) {
          clearInterval(interval);
        }
      }, 1000);

      await new Promise((resolve) => setTimeout(resolve, 20000));
    }
  }

  if (!isSuccess.value) {
    console.log("No se encontró el usuario después de varios intentos.");
  }
}
</script>
