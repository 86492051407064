<template>
  <div class="w-full h-[70vh]">
    <div class="flex flex-col h-full gap-4">
      <Typography size="main-xl" class="text leading-none text-nowrap"
        >Terms and Conditions</Typography
      >

      <div
        v-html="aboutContent"
        class="flex flex-col gap-2 flex-1 pr-8 mt-4 overflow-y-auto customAboutStyles"
      ></div>
    </div>
    <img
      src="~/assets/gif/gif-logo-albarra.gif"
      class="absolute hidden md:block right-0 m-4 -translate-y-6 w-12 h-12"
      alt=""
    />
  </div>
</template>
  
  <script setup>
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "../../stores/useModalsStore";
import { useAuthStore } from "../../stores/useAuthStore";
import { cmsStore } from "../../stores/cmsStore";

const authStore = useAuthStore();
const modalsStore = useModalsStore();
const cms = cmsStore();

const aboutContent = ref("");

async function loadAboutContent() {
  const params = {
    filter: {
      id: 2,
    },
  };

  const response = await cms.get("page_content", params);
  aboutContent.value = response.data[0].content;
  console.log(response);
}

onMounted(() => {
  loadAboutContent();
});
</script>
  
  <style >
.customAboutStyles h3 {
  font-weight: bold;
}
.customAboutStyles p {
  margin-bottom: 1rem;
}
.customAboutStyles > ul {
  list-style-type: disc;
  @apply pl-4  grid  mb-4;
}
.customAboutStyles > ul > li {
}
.customAboutStyles > ul > li > p {
  margin-bottom: 0rem;
}

.customAboutStyles a {
  @apply underline font-bold underline-offset-2;
}
</style>
  