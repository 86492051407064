<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="leading-none -mt-5">
      You are not in Discord
    </Typography>
    <img
      src="~/assets/images/albarra_discord_quest.png"
      class="h-40 object-contain"
    />
    <Typography class="text-center">
      To complete this quest, you need to join our Discord server.
    </Typography>
    <button
      @click="closeModal"
      class="bg-primary text-white px-8 rounded mx-auto flex items-center gap-4"
    >
      <span class="flex gap-2 items-center">
        <Typography size="main-l" class="flex gap-2">OK</Typography>
      </span>
    </button>
  </div>
</template>
  
  <script setup>
import { ref } from "vue";
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "~/stores/useModalsStore";
import { useAuthStore } from "~/stores/useAuthStore";

const modalsStore = useModalsStore();

function closeModal() {
  if(modalsStore.callback) {
    modalsStore.callback()
  }
  modalsStore.closeModal()
}
</script>
  