<template>
  <div class="flex flex-col gap-6 justify-items-center items-center">
    <img src="https://albarra.world/cms/assets/c39ac9d0-deaa-4782-bb55-df790ab90b6b" alt="" style="max-width: 350px;margin: 0% auto;">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">Tomahawk Badge!</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center">Congrats! You've earned this badge for setting your first high score in the Albarra Tomahawk minigame!</Typography>
    </span>
    <Button @click="onClickClaim"
            class="h-16 px-10 bg-primary font-bold text-2xl flex items-center justify-center gap-4">
      <span style="font-size: 40pt; font-family: Ravenholm;font-weight: normal !important;">Claim Badge</span>
    </Button>
  </div>
</template>
  
<script setup>
import Typography from "@/components/common/Typography.vue"
import { cmsStore } from "../../stores/cmsStore";
import { useModalsStore } from '../../stores/useModalsStore'

const modalsStore = useModalsStore()
const cms = cmsStore();

const logs = ref(modalsStore.modalPayload?.logs)

const onClickClaim = () => {
  const TOMAHAWK_BADGE_ID = 46
  cms.userHasBadge(TOMAHAWK_BADGE_ID)
     .then(response => {
        console.log(response)
        if (response.code === 'success-badge-assigned-now') {
          modalsStore.closeModal()
          window.location.href = '/badges'
        }
     })
}

const closeModal = () => {
  modalsStore.closeModal()
  navigateTo('/badges')
}
</script>
  
<style scoped>
</style>
  