<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">Oops!</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center">Retweet not found. Please make sure you've done so before verifying again</Typography>
    </span>
  </div>
</template>
  
<script setup>
import Typography from "@/components/common/Typography.vue"
import { useModalsStore } from '../../stores/useModalsStore'
import { useAuthStore } from '../../stores/useAuthStore'

const authStore = useAuthStore()
const modalsStore = useModalsStore()

const doLogin = () => {
  authStore.login()
}

const closeModal = () => {
  modalsStore.closeModal()
}
</script>

<style scoped>
</style>
