<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">The Rail</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <Typography size="secondary-book" class="mt-5 text-center">Enter here verification code of The Rail for claim this quests.</Typography>
    <input v-model="theRailCode" type="text" class="input-field border px-4 py-2 rounded">
    <div v-if="error" class="text-red-500 text-center">{{ error }}</div>
    <button class="mt-10 bg-primary text-white px-20 py-2 rounded mx-auto flex items-center gap-4"
            @click="submitTheRailCode">
      <Typography size="main-l">Verify</Typography>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useFetch } from '#app'
import { useClaimsStore } from '../../stores/useClaimsStore'
import { useModalsStore } from '../../stores/useModalsStore'
import Typography from "@/components/common/Typography.vue";

const claimsStore = useClaimsStore()
const modal = useModalsStore()
const theRailCode = ref('')
const error = ref('')

const submitTheRailCode = async () => {
  if (!theRailCode.value) {
    error.value = 'The Rail Code is required'
    if (modal.callback) {
      modal.callback(false)
    }
    return
  }
  try {
    const response = await $fetch('/api/the-rail/code/verify', {
      method: 'POST',
      body: { code: theRailCode.value },
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
    console.log("response", response);
    if (!response.isValid) {
      error.value = 'Invalid The Rail Code'
      if (modal.callback) {
        modal.callback(false)
      }
      return
    }
    claimsStore.updateVerificationCode(theRailCode.value)
    if (modal.callback) {
      modal.callback(true)
    }
    modal.closeModal()
    modal.openModal('SUCCESS_THE_RAIL_QUEST')
  } catch (err) {
    console.error('Failed to verify The Rail Code', err)
    error.value = err.message || 'Failed to verify The Rail Code'
    if (modal.callback) {
      modal.callback(false)
    }
  }
}
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 320px;
}

.input-field {
  width: 100%;
  color: black;
}
</style>
