import { defineStore } from 'pinia'
import { useAuthStore } from './useAuthStore'
import { cmsStore } from './cmsStore'
import { sleep } from '~/utils/time.utils'

export const useClaimsStore = defineStore('claimsStore', {
    state: () => ({
      verificationCode: '',
    }),
    actions: {
      async checkClaimedStoryQuests({ episode_id }) {
        const cms = cmsStore()
        const auth = useAuthStore()
        const user_id = auth.user.directusUser.id
        const params = {
          filter: {
            user_id,
            episode_id,
          },
        }
        const response = await cms.get('story_quests_claims_users', params)

        if (!response) {
          console.error(`Error fetching ${collection}:`, error.value)
        }
        return response
      },
      async requestClaimStoryQuests({ episode_id }) {
        const path = `/api/claims/story-quests`
        const response = await $fetch(path, {
          method: 'POST',
          body: {
            episode_id
          },
          credentials: 'include',
        })
        if (!response) {
          console.error(`Error fetching ${collection}:`, error.value)
        }
        return response
      },
      async requestClaimCommentTweet({ bonus_id }) {
        const path = '/api/claims/comment/twitter'
        const response = await $fetch(path, {
          method: 'POST',
          body: {
            bonus_id
          },
          credentials: 'include',
        })
        if (!response) {
          console.error(`Error fetching ${collection}:`, error.value)
        }
        return response
      },
      async requestClaimLikeAndRetweet({ bonus_id }) {
        const AWAIT_30_SECONDS = 30000
        await sleep(AWAIT_30_SECONDS)
        const path = `/api/claims/like-and-retweet/twitter`
        const response = await $fetch(path, {
          method: 'POST',
          body: {
            bonus_id
          },
          credentials: 'include',
        })
        if (!response) {
          console.error(`Error fetching ${collection}:`, error.value)
        }
        return response
      },
      async requestClaimTheRail({ bonus_id }) {
        const path = '/api/the-rail/code/claim'
        const response = await $fetch(path, {
          method: 'POST',
          body: {
            bonus_id,
            code: this.verificationCode,
          },
          credentials: 'include',
        })
        if (!response) {
          console.error(`Error fetching ${collection}:`, error.value)
        }
        return response
      },
      updateVerificationCode(code) {
        this.verificationCode = code
      }
    },
})
