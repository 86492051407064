<!-- Modal.vue -->
<template>
  <div
    class="fixed h-screen w-screen bg-black top-0 z-50 left-0 bg-opacity-50 flex items-center justify-center backdrop-blur-sm "
    v-if="isOpen"
  >
    <div
      :class="[
        'relative bg-graybase  rounded-lg border-2 border-primary',
        additionalClass,
        modalId === 'JOIN_DISCORD' ? 'border-purple' : '',
      ]"
    >
      <div class="w-full h-full py-4 lg:py-16 px-8 lg:px-20 max-h-[95vh] overflow-y-auto max-w-2xl lg:max-w-4xl relative">
        <LucideX
          v-if="showCloseButton"
          @click="closeModal"
          class="absolute top-0 right-0 m-4 lg:m-6 cursor-pointer"
        />

        <!-- CONTENIDO DE LA MODAL SEGÚN EL ID -->
        <component :is="getModalComponent(modalId)" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { useModalsStore } from "~/stores/useModalsStore";
import { computed } from "vue";
import NoTapeNoFun from "~/components/modals/NoTapeNoFun.modal.vue";
import VerifyRole from "~/components/modals/VerifyRole.modal.vue";
import ClaimYourBadge from "~/components/modals/ClaimYourBadge.modal.vue";
import JoinDiscord from "~/components/modals/JoinDiscord.modal.vue";
import QuizQuestModal from "./QuizQuest.modal.vue";
import LoginModal from "./Login.modal.vue";
import WelcomeModal from "./Welcome.modal.vue";
import UserNotCommented from "./UserNotCommented.modal.vue";
import UserNotLikeded from "./UserNotLikeded.modal.vue";
import UserNotRetweeted from "./UserNotRetweeted.modal.vue";
import LinkOtherPage from "./LinkOtherPage.modal.vue";
import SuccessStoryQuests from "./SuccessStoryQuests.modal.vue";
import TwitterErrorTooManyRequests from "./TwitterErrorTooManyRequests.modal.vue";
import ErrorStoryQuests from "./ErrorStoryQuests.modal.vue";
import SuccessClaimGotAxedBadge from "./SuccessClaimGotAxedBadge.modal.vue";
import SuccessClaimOtherPageBadge from "./SuccessClaimOtherPageBadge.modal.vue";
import SuccessClaimTomahawkBadge from "./SuccessClaimTomahawkBadge.modal.vue";
import SuccessClaimWoodCollectorBadge from "./SuccessClaimWoodCollectorBadge.modal.vue";
import AboutModal from "./About.vue";
import FaqModal from "./Faq.modal.vue";
import TermsModal from "./Terms.modal.vue";
import PrivacyModal from "./PrivacyPolicy.modal.vue";
import ClaimDiscordRoles from "./ClaimDiscordRoles.modal.vue";
import YouAreNotInDiscord from "./YouAreNotInDiscord.modal.vue";
import SuccessLogin from "./SuccessLogin.vue";
import CloseSession from "./CloseSession.modal.vue";
import UserNotFollowTwitterAccount from "./UserNotFollowTwitterAccount.vue";
import TheRailQuest from "./TheRailQuest.vue";
import SuccessTheRailQuest from './SuccessTheRailQuest.modal.vue';
import SuccessClaimTheRailBadge  from './SuccessClaimTheRailBadge.model.vue';
import SuccessClaimHotStreakBadge from './SuccessClaimHotStreakBadge.modal.vue';
import SuccessClaimBadge from './SuccessClaimBadge.modal.vue';
import SessionExpired from './SessionExpired.vue'
import TheRailCode from './TheRailCode.modal.vue';
import SuccessClaimBoringSecurityBadge from './SuccessClaimBoringSecurityBadge.modal.vue';
import SuccessClaimHotStreak100Badge from './SuccessClaimHotStreak100Badge.modal.vue';

export default {
  components: {
    NoTapeNoFun,
    VerifyRole,
    ClaimYourBadge,
    JoinDiscord,
    QuizQuestModal,
    LoginModal,
    WelcomeModal,
    UserNotCommented,
    UserNotLikeded,
    UserNotRetweeted,
    LinkOtherPage,
    SuccessStoryQuests,
    TwitterErrorTooManyRequests,
    ErrorStoryQuests,
    SuccessClaimGotAxedBadge,
    SuccessClaimOtherPageBadge,
    SuccessClaimTomahawkBadge,
    SuccessClaimWoodCollectorBadge,
    AboutModal,
    FaqModal,
    TermsModal,
    PrivacyModal,
    ClaimDiscordRoles,
    YouAreNotInDiscord,
    SuccessLogin,
    CloseSession,
    UserNotFollowTwitterAccount,
    TheRailQuest,
    SuccessTheRailQuest,
    SuccessClaimTheRailBadge,
    SuccessClaimHotStreakBadge,
    SuccessClaimBadge,
    SessionExpired,
    TheRailCode,
    SuccessClaimBoringSecurityBadge,
    SuccessClaimHotStreak100Badge,
  },
  setup() {
    const modalsStore = useModalsStore();
    const isOpen = computed(() => modalsStore.isOpen);
    const noShowCloseButton = ['CLAIM_DISCORD_ROLES', 'YOU_ARE_NOT_IN_DISCORD','SUCCESS_LOGIN','CLOSE_SESSION_AND_LOGIN_AGAIN', 'SESSION_EXPIRED'];
    const modalId = computed(() => {
      showCloseButton.value = !noShowCloseButton.includes(modalsStore.modalId);
      return modalsStore.modalId;
    });
    let showCloseButton = ref(true);
    const closeModal = modalsStore.closeModal;

    const getModalComponent = (modalId) => {
      switch (modalId) {
        case "NO_TAPE_NO_FUN":
          return "NoTapeNoFun";
        case "VERYFY_ROLE":
          return "VerifyRole";
        case "CLAIM_YOUR_BADGE": // TODO DELET THIS MODAL
          return "ClaimYourBadge";
        case "JOIN_DISCORD":
          return "JoinDiscord";
        case "QUIZ_QUEST":
          return "QuizQuestModal";
        case "LOGIN":
          return "LoginModal";
        case "WELCOME":
          return "WelcomeModal";
        case "USER_NOT_COMMENTED":
          return "UserNotCommented";
        case "USER_NOT_LIKED":
          return "UserNotLikeded";
        case "USER_NOT_RETWEETED":
          return "UserNotRetweeted";
        case "LINK_OTHER_PAGE":
          return "LinkOtherPage";
        case "SUCCESS_STORY_QUESTS":
          return "SuccessStoryQuests";
        case "ERROR_TOO_MANY_REQUESTS":
          return "TwitterErrorTooManyRequests";
        case "ERROR_STORY_QUESTS":
          return "ErrorStoryQuests";
        case "SUCCESS_CLAIM_GOT_AXED_BADGE":
          return "SuccessClaimGotAxedBadge";
        case "SUCCESS_CLAIM_OTHER_PAGE_BADGE":
          return "SuccessClaimOtherPageBadge";
        case "SUCCESS_CLAIM_TOMAHAWK_BADGE":
          return "SuccessClaimTomahawkBadge";
        case "SUCCESS_CLAIM_WOOD_COLLECTOR_BADGE":
          return "SuccessClaimWoodCollectorBadge";
        case "ABOUT":
          return "AboutModal";
        case "FAQ":
          return "FaqModal";
        case "TERMS":
          return "TermsModal";
        case "PRIVACY":
          return "PrivacyModal";
        case "CLAIM_DISCORD_ROLES":
          return "ClaimDiscordRoles";
        case "YOU_ARE_NOT_IN_DISCORD":
          return "YouAreNotInDiscord";
        case "SUCCESS_LOGIN":
          return "SuccessLogin";
        case "CLOSE_SESSION_AND_LOGIN_AGAIN":
          return "CloseSession";
        case "USER_NOT_FOLLOW_TWITTER_ACCOUNT":
          return "UserNotFollowTwitterAccount";
        case 'THE_RAIL_QUEST':
          return 'TheRailQuest';
        case 'SUCCESS_THE_RAIL_QUEST':
          return 'SuccessTheRailQuest';
        case 'SUCCESS_CLAIM_THE_RAIL_BADGE':
          return 'SuccessClaimTheRailBadge';
        case 'SUCCESS_CLAIM_HOT_STREAK_BADGE':
          return 'SuccessClaimHotStreakBadge';
        case 'CLAIM_BADGE':
          return 'SuccessClaimBadge';
        case 'SESSION_EXPIRED':
          return 'SessionExpired'
        case 'THE_RAIL_CODE':
          return 'TheRailCode';
        case 'SUCCESS_CLAIM_BORING_SECURITY_BADGE':
          return 'SuccessClaimBoringSecurityBadge';
        case 'SUCCESS_CLAIM_HOT_STREAK_100_BADGE':
          return 'SuccessClaimHotStreak100Badge';
        default:
          return null;
      }
    };

    return {
      isOpen,
      modalId,
      closeModal,
      showCloseButton,
      getModalComponent,
    };
  },
  props: {
    additionalClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
</style>
  