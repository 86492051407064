<template>
  <div>
    <!-- <div v-if="!userHasClaimedRoles" class="flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <Typography size="main-xl" class="leading-none">
          Verify discord roles
        </Typography>
        <p class="text-center">
          Verifica tus roles de discord para ganar recompensas 1 role = 1 🪵
        </p>
      </div>

      <button
        @click="claimDiscordRoles"
        class="bg-primary text-white px-8 rounded mx-auto flex items-center gap-4"
      >
        <Typography size="main-l">Verifica roles</Typography>
      </button>
    </div> -->
    <div v-if="claimedRoles?.length > 0" class="flex flex-col gap-6">
      <div v-if="!userHaveAllRoles" class="flex flex-col items-center gap-2">
        <Typography size="main-xl" class="leading-none -mt-5">
          You have new roles
        </Typography>
        <div class="flex flex-col gap-2 items-center">
          <p class="flex gap-2">Congrats you claim the next roles:</p>
          <div class="flex gap-2 flex-wrap items-center justify-center">
            <span
              v-for="role in claimedRoles"
              :key="role"
              class="px-2 border border-primary rounded"
              >{{ role }}</span
            >
          </div>
        </div>
      </div>
      <div v-if="userHaveAllRoles" class="flex flex-col gap-2">
        <Typography size="main-l" class="leading-none text-center">
          WOW, You have all the roles!
        </Typography>
      </div>
      <div class="grid md:grid-cols-2 gap-4">
        <span
          v-for="role in claimeableRoles"
          :key="role"
          :class="{
            'p-2 border border-primary text-center rounded relative mr-8 ': true,
            'opacity-55':
              !actualyUserRoles.includes(role) && !claimedRoles.includes(role),
            'opacity-100':
              actualyUserRoles.includes(role) || claimedRoles.includes(role),
          }"
          >{{ role }}
          <span
            v-if="
              actualyUserRoles.includes(role) || claimedRoles.includes(role)
            "
            class="absolute right-0 top-0"
          >
            <img
              src="~/assets/svg/correct.svg"
              alt=""
              class="w-5 h-5 translate-x-2 -translate-y-2"
            />
          </span>
        </span>
      </div>

      <button
        @click="
          () => {
            // función doneVerifyRoles
            // comprobar si tengo callback en el modal
            // si tengo callback debemos enviar un parametro con: { userHaveAllRoles }
            if (modalsStore.callback) {
              modalsStore.callback({ userHaveAllRoles })
            }
            modalsStore.closeModal()
          }
        "
        class="bg-primary text-white px-8 rounded mx-auto flex items-center gap-4"
      >
        <span class="flex gap-2 items-center">
          <Typography size="main-l" class="flex gap-2">Claim +</Typography>
          <Typography size="main-l-num" class="flex gap-2">
            {{ claimedRoles.length * 30 }}
          </Typography>
          <img src="~/assets/svg/troncos.svg" class="w-8 h-8" />
        </span>
      </button>
    </div>
    <div
      v-if="!claimedRoles && actualyUserRoles?.length > 0"
      class="flex flex-col items-center gap-6"
    >
      <Typography size="main-l" class="leading-none text-center max-w-xl">
        We can't find any new <span class="tracking-widest">NFT's</span> to
        assign more parent roles in the Albarra Discord.
      </Typography>
      <Typography class="text-center max-w-xl">
        If you're encountering any issues, please ensure you have verified and
        configured Vulcan correctly and try again!
      </Typography>

      <button
        @click="() => {
          // doneNotHasMoreRoles
          if (modalsStore.callback) {
            modalsStore.callback()
          }
          modalsStore.closeModal()
        }"
        class="bg-primary text-white px-8 rounded mx-auto flex items-center gap-4"
      >
        <Typography size="main-l">OK</Typography>
      </button>
    </div>
    <div v-if="!claimedRoles && actualyUserRoles?.length === 0"
         class="flex flex-col gap-6">
      <Typography size="main-xl" class="leading-none text-center">
        No Discord NFT roles found
      </Typography>
      <Typography class="text-center">
        Please head over to the Albarra Discord “vulcan-verify” channel,
        complete the process and try again.
      </Typography>
      <button @click="handleGoToDiscordVulcanVerify"
              class="bg-primary text-white px-8 rounded mx-auto flex items-center gap-4">
        <Typography size="main-l">Go to Discord</Typography>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "~/stores/useModalsStore";
import { useAuthStore } from "~/stores/useAuthStore";
import { cmsStore } from "~/stores/cmsStore";	

const modalsStore = useModalsStore();
const auth = useAuthStore();
const url_base = useRuntimeConfig().public.apiLogicUrl;
const cms = cmsStore();


const claimedRoles = ref([]);
const claimeableRoles = ref([]);
const actualyUserRoles = ref([]);
const userHaveAllRoles = ref(false);

const checkUserJoinDiscord = async () => {
  const userDiscordId = auth.user.directusUser?.discord_id;

  const response = await $fetch(`${url_base}/claims/discord/claim-roles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      discord_id: userDiscordId,
      bonus_id: modalsStore.modalPayload.quest.id,
    }),
    credentials: "include",
  });

  console.log("response", response);
  claimedRoles.value = response.claimedRoles;
  claimeableRoles.value = response.claimeableRoles;
  actualyUserRoles.value = response.actualyUserRoles;
  userHaveAllRoles.value = response.userHaveAllRoles;
};

function claimDiscordRoles() {
  checkUserJoinDiscord()
  .then(() => {
    cms.loadUserLogs().then();
    cms.loadNotifications().then();
    cms.loadBonusQuests().then(cms.loadCompletedBonusQuests);
  });
}

function handleGoToDiscordVulcanVerify() {
  window.open(
    modalsStore.modalPayload.quest.values[0].discord_join_link,
    "_blank"
  );
  modalsStore.closeModal();
}


onMounted(() => {
  claimDiscordRoles();
});
</script>
