<template>
  <div class="flex flex-col gap-6">
    <div class="w-full grid grid-cols-3 lg:grid-cols-2 ">
      <div class="w-full">
        <img src="~/assets/images/Nedge_About.png" class="h-full absolute -translate-x-[20%] left-0 top-0" alt="">
      </div>
      <div class="flex flex-col gap-4 col-span-2 lg:col-span-1">
        <Typography size="main-xl" class="text-primary leading-none text-nowrap"
          >About</Typography
        >

        <div v-html="aboutContent" class="flex flex-col  gap-2 z-10"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "../../stores/useModalsStore";
import { useAuthStore } from "../../stores/useAuthStore";
import { cmsStore } from "../../stores/cmsStore";

const authStore = useAuthStore();
const modalsStore = useModalsStore();
const cms = cmsStore();

const aboutContent = ref("");

async function loadAboutContent() {
  const params = {
    filter: {
      slug: "about",
    },
  };

  const response = await cms.get("page_content", params);
  aboutContent.value = response.data[0].content;
}

onMounted(() => {
  loadAboutContent();
});
</script>

<style scoped></style>
