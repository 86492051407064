<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">The Rail Code</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <Typography size="secondary-book" class="mt-2 text-center">Your unique The Rail Code</Typography>
    <div
      :class="{
        'p-4 bg-neutral-700 mt-4 shadow-md rounded flex transition-all relative': true,
        'bg-primary': isCopied,
        'bg-neutral-700': !isCopied,
      }"
    >
      <Typography
        size="main-md"
        :class="{
          'text absolute text-primary left-0 select-none top-0 text-center w-full transition-all': true,
          'opacity-1 -translate-y-9': copiedSignal,
          'opacity-0 -translate-y-0': !copiedSignal,
        }"
      >
        Copied!
      </Typography>
      <input type="text" class="bg-transparent border-none w-full max-w-80 focus:border-none outline-none" :value="theRailCode">
    </div>
    <button
      :class="{
        'mt-4 bg-primary text-white px-20 py-2 rounded mx-auto flex items-center gap-4 transition-all': true,
      }"
      @click="handleCopyCode"
    >
      <Typography size="main-l"> Copy Code </Typography>
    </button>
  </div>
</template>
  
  <script setup>
import { ref } from "vue";
import { useFetch } from "#app";
import { useAuthStore } from "../../stores/useAuthStore";
import { useModalsStore } from "../../stores/useModalsStore";
import Typography from "@/components/common/Typography.vue";

const auth = useAuthStore();
const modal = useModalsStore();
const walletAddress = ref("");
const isCopied = ref(false);
const theRailCode = ref("000");
const copiedSignal = ref(false);
const error = ref("");

const handleCopyCode = () => {
  navigator.clipboard.writeText(theRailCode.value);
  console.log('copied', theRailCode.value);
  isCopied.value = true;
  copiedSignal.value = true;
  setTimeout(() => {
    isCopied.value = false;
  }, 200);
};

const validateWallet = (address) => /^0x[a-fA-F0-9]{40}$/.test(address);

const submitWalletAddress = async () => {
  if (!walletAddress.value) {
    error.value = "Wallet address is required";
    return;
  }
  if (!validateWallet(walletAddress.value)) {
    error.value = "Invalid wallet address";
    return;
  }
  try {
    const userId = auth.user.directusUser.id;
    const { data, error } = await useFetch(`/api/user/${userId}/other_page`, {
      method: "PUT",
      body: { otherPage: walletAddress.value },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (error.value) {
      throw new Error(error.message);
    }
    const userPayload = data.value.data;
    error.value = "";
    auth.updateUserOtherPage(userPayload.other_page);
    modal.closeModal();
  } catch (err) {
    console.error("Error updating wallet address:", err);
    error.value = err.message || "Failed to update wallet address";
  }
};

onMounted(() => {
  theRailCode.value = auth.theRailCode;
  console.log(theRailCode.value);
});
</script>
  
  <style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 320px;
}
</style>
  