<template>
  <div v-if="showModal"
    class="md:hidden fixed inset-0 z-50 bg-black flex items-center flex-col justify-center select-none pointer-events-none overflow-hidden"
  >
    <img
      src="https://albarra.world/cms/assets/7b74a204-f06f-4ee1-9b3a-76a3fdd394c8"
      alt=""
      class="absolute h-full opacity-25 inset-0 object-cover"
    />
    <div class="relative flex items-center justify-center">
      <LucideSmartphone class="text-primary absolute h-8 w-8" />
      <LucideRefreshCcw class="text-primary h-24 w-24" strokeWidth="{1}" />
    </div>
    <div class="w-full max-w-[400px] rounded-lg p-4 flex flex-col gap-8">
      <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
      <Typography size="secondary-book" class="text-center">
        Hey fren, for the best experience, please rotate your phone to landscape
        mode. Let's get to chopping!
      </Typography>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Typography from "@/components/common/Typography.vue";

const showModal = ref(false);

const checkOrientation = () => {
  showModal.value = window.matchMedia("(orientation: portrait)").matches;
};

onMounted(() => {
  checkOrientation();
  window.addEventListener('resize', checkOrientation);
});

onUnmounted(() => {
  window.removeEventListener('resize', checkOrientation);
});
</script>
