<!-- ClaimYourBadge.vue -->
<template>
  <div class="flex flex-col gap-2">
    <Typography size="main-xl" class="text-center leading-none text-nowrap"
      >Claim your badge</Typography
    >
    <Typography size="secondary-book" class="text-center"
      >Congratulations! Wood Collector role verified Claim your "No Tape No Fun"
      badge (off-chain asset)</Typography
    >
    <img
      src="~/assets/images/badge.png"
      alt="Discord"
      class="w-[22rem] mx-auto"
    />
    <button
      @click="handleClaimBadge"
      class="bg-primary text-white px-6 rounded mx-auto flex items-center gap-4"
    >
      <Typography size="main-l"> Claim Badge </Typography>
    </button>
    <span>
      <Typography size="secondary-book" class="text-center"
        ><b>58/200 claimed</b></Typography
      >
    </span>
  </div>
</template>
  
  <script>
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "~/stores/useModalsStore";

export default {
  components: {
    Typography,
  },
  setup() {
    const modalsStore = useModalsStore();
    const handleClaimBadge = async () => {
      const response = await assingLogs();
      if (response) {
        alert("🟢 Badge claimed 🟢");
        modalsStore.closeModal();
      } else {
        alert("🔴 Error claiming badge 🔴");
      }
    };

    async function assingLogs(userId, logs, type, source) {
      const data = {
        user: "3e31eb85-8978-4be3-b1af-c8f9afb85c3c",
        logs: 0,
        type: "add",
        source: "game",
      };

      try {
        const response = await fetch("https://api.albarra.world/cms/assign-logs", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error(
            "Error al realizar la petición HTTP: " + response.statusText
          );
        }
        return response.json();
      } catch (error) {
        console.error("Error al realizar la petición:", error);
      }
    }

    return {
      handleClaimBadge,
    };
  },
};
</script>
  
  <style scoped>
</style>
  