<!-- NoTapeNoFun.vue -->
<template>
  <div class="flex flex-col gap-6">
    <img
      src="~/assets/images/no-tape-no-fun.png"
      alt="Discord"
      class="w-[28rem] mx-auto"
    />
    <Typography size="main-xl" class="text-center leading-none text-nowrap"
      >No tape, no fun!</Typography
    >
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center"
        >Midorifest needs your urgent help to fix the sound cable!</Typography
      >
      <Typography size="secondary-book" class="text-center"
        ><b>Mission: Find the roll of duct tape in Nedge's room.</b></Typography
      >
    </span>
    <button
      class="bg-primary text-white px-6 rounded mx-auto flex items-center gap-4"
    >
      <Typography size="main-l"> Challenge accepted! </Typography>
    </button>
  </div>
</template>
  
  <script>
import Typography from "@/components/common/Typography.vue";

export default {
  components: {
    Typography,
  },
};
</script>
  
  <style scoped>
</style>
  