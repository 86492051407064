<!-- VerifyRole.vue -->
<template>
  <div class="flex flex-col gap-2">
    <Typography size="main-xl" class="text-center leading-none text-nowrap"
      >You found the duct tape!</Typography
    >
    <Typography size="secondary-book" class="text-center"
      >Verify that you have the "Wood collector" role on Discord to proceed to
      claim your 1/200 badge</Typography
    >
    <img
      src="~/assets/tape-found.png"
      alt="Discord"
      class="w-[28rem] mx-auto"
    />
    <button
      @click="handleJoinDiscord"
      class="bg-purple text-white px-6 rounded mx-auto flex items-center gap-4"
    >
      <Typography size="main-l"> Verify Discord role </Typography>
      <img
        src="~/assets/svg/discord-icon.svg"
        alt="discord-logo"
        class="w-8 h-8"
      />
    </button>
  </div>
</template>
  
  <script>
import Typography from "@/components/common/Typography.vue";
import { useModalsStore } from "~/stores/useModalsStore";

export default {
  components: {
    Typography,
  },
  setup() {
    const modalsStore = useModalsStore();
    const handleJoinDiscord = async () => {
      const discordUserId = "939786268701778013";

      const { data } = await useFetch(
        `/api/discord/hasjoined/${discordUserId}`
      );

      if (data.value === true) {
        modalsStore.openModal("CLAIM_YOUR_BADGE");
        alert("🟢Tu estás en el servidor de discord 🟢");
      } else {
        alert("🔴 You need to join the discord server first 🔴");
      }
    };

    return {
      handleJoinDiscord,
    };
  },
};
</script>
  
  <style scoped>
</style>
  