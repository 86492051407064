<!-- NoTapeNoFun.vue -->
<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-wrap">Welcome to the Albarra Woods</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center">Join Nedge on his quest to bring back TDRC. Earn logs as you take part in the story and participate in immersive experiences, minigames, using new Web3 UX products and more.</Typography>
    </span>
    <button class="bg-primary text-white px-20 py-2 rounded mx-auto flex items-center gap-4"
            @click="onNext">
      <Typography size="main-l"> Continue </Typography>
    </button>
  </div>
</template>
  
<script setup>
import Typography from "@/components/common/Typography.vue"
import { useModalsStore } from '../../stores/useModalsStore'
import { useAuthStore } from '../../stores/useAuthStore'

const authStore = useAuthStore()
const modalsStore = useModalsStore()

const onNext = () => {
  modalsStore.closeModal()
  modalsStore.openModal('LOGIN')
}

</script>
  
<style scoped>
</style>
  