<!-- NoTapeNoFun.vue -->
<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">Let's get started!</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center">Connect your X Account to earn your very first logs and start exploring the Albarra universe.</Typography>
    </span>
    <button class="bg-primary text-white px-20 py-2 rounded mx-auto flex items-center gap-4"
            @click="doLogin">
      <Typography size="main-l"> Log in with </Typography>
      <span class="rounded-full"><img src="~/assets/svg/x-logo-twiter.svg" class="w-8 h-8 rounded-full" /></span>
    </button>
    <button class="text-white mx-auto flex items-center gap-4"
            @click="closeModal">
      <Typography size="secondary-sm"> Skip for now, I want to look around </Typography>
    </button>
  </div>
</template>
  
<script setup>
import Typography from "@/components/common/Typography.vue"
import { useModalsStore } from '../../stores/useModalsStore'
import { useAuthStore } from '../../stores/useAuthStore'

const authStore = useAuthStore()
const modalsStore = useModalsStore()

const doLogin = () => {
  authStore.login()
}

const closeModal = () => {
  modalsStore.closeModal()
}
</script>
  
<style scoped>
</style>
  