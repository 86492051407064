<template>
  <div>
    <div v-if="!responseIsSubmited" class="flex flex-col gap-6">
      <Typography size="main-xl" class="text-center leading-none text-nowrap">
        Weekly Quiz
      </Typography>
      <div class="w-full flex flex-col gap-4">
        <p class="text-xl font-gotham font-semibold text-primary">
          {{ selectedQuiz?.answer }}
        </p>
        <ul :class="{ 'flex flex-col gap-4': true }">
          <li
            v-for="(response, index) in selectedQuiz?.responses || []"
            :key="index"
            :class="{
              'flex items-center gap-2 relative': true,
            }"
          >
            <span
              :class="{
                'absolute left-0 -translate-x-full -mx-6 font-semibold': true,
                'text-primary': response.response === selectedResponse,
              }"
              >{{ String.fromCharCode(65 + index) }}</span
            >
            <div
              @click="() => selectResponse(response)"
              :class="{
                'p-4 py-2 transition-all border border-transparent bg-neutral-700 shadow rounded w-full cursor-pointer': true,
                '  border  !border-primary ':
                  response.response === selectedResponse,
              }">
              {{ response.response }}
            </div>
          </li>
        </ul>
      </div>
      <button
        :class="{
          'bg-primary text-white px-6 rounded mx-auto flex items-center gap-4': true,
          'opacity-50 cursor-not-allowed': !selectedResponse,
        }">
        <Typography size="main-l" class="text-xl p-4" @click="submitResponse">Submit</Typography>
      </button>
      <div class="flex flex-col gap-2">
        <img
          src="~/assets/images/boring_security_logo.png"
          class="w-10 h-10 md:w-16 md:h-16 mx-auto"
          alt=""
        />
        <Typography class="text-center !text-sm">Powered by Boring Security DAO</Typography>
        <Typography extraClasses="text-center !text-sm">
          Want to learn more? <a :href="selectedQuiz?.link" target="_blank" class="cursor-pointer underline hover:text-primary">Visit Boring Security</a>
        </Typography>
      </div>
    </div>
    <div v-if="responseIsSubmited" class="">
      <div v-if="responseIsCorrect" class="flex flex-col gap-4">
        <img src="~/assets/svg/correct.svg" class="w-16 h-16 mx-auto" alt="" />
        <Typography size="main-xl" class="text-center leading-none text-nowrap">
          Correct!
        </Typography>
        <Typography class="text-center">
          You've just earned 30 logs for correctly answering this weeks Boring Security Quiz!
        </Typography>
        <!--
        <button
          class="bg-primary text-white px-6 rounded mx-auto flex items-center gap-4"
        >
          <Typography size="main-l" class="text-xl p-4 flex items-center gap-4"
            >Claim <img src="~/assets/svg/troncos.svg" class="w-6 h-6" />
            <Typography size="main-l-num" class="">30</Typography>
          </Typography>
        </button>
        -->
        <div class="flex flex-col gap-2">
          <img
            src="~/assets/images/boring_security_logo.png"
            class="w-10 h-10 md:w-16 md:h-16 mx-auto"
            alt=""
          />
          <Typography class="text-center !text-sm">Powered by Boring Security DAO</Typography>
          <Typography class="text-center !text-sm">
            Want to learn more? <a :href="selectedQuiz?.link" target="_blank" class="cursor-pointer underline hover:text-primary">Visit Boring Security</a>
          </Typography>
        </div>
      </div>
      <div v-if="!responseIsCorrect" class="flex flex-col gap-4">
        <Typography size="main-xl" class="text-center leading-none text-nowrap">
          Incorrect answer!
        </Typography>
        <Typography class="text-center">
          Learn more about the topic:
        </Typography>
        <!-- TODO: cambiar el link del bonusquest -->
        <div
          @click="reloadPageAndOpenLink"
          class="w-full text-center cursor-pointer"
        >
          <Typography size="main-l" class="underline text-primary mx-auto">Here</Typography>
        </div>
        <div class="flex flex-col gap-2">
          <img
            src="~/assets/images/boring_security_logo.png"
            class="w-10 h-10 md:w-16 md:h-16 mx-auto"
            alt=""
          />
          <Typography class="text-center !text-sm">Powered by Boring Security DAO</Typography>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Typography from "@/components/common/Typography.vue";
import { cmsStore } from "../stores/cmsStore";
import { useAuthStore } from "../stores/useAuthStore";

export default {
  components: {
    Typography,
  },
  props: ["value"],
  setup() {
    const selectedQuiz = ref(null);
    const selectedResponse = ref(null);
    const responseIsSubmited = ref(null);
    const responseIsCorrect = ref(null);
    const modalsStore = useModalsStore();
    const cms = cmsStore();
    const authStore = useAuthStore();

    const selectResponse = (response) => {
      selectedResponse.value = response.response;
    };

    const loadQuiz = async () => {
      const collectionParams = {
        filter: {
          id: modalsStore.modalPayload.quest.values[0].quiz_id,
        },
      };
      const result = await cms.get("quiz", collectionParams);
      selectedQuiz.value = result.data[0];
    };

    const submitResponse = async () => {
      if (!selectedResponse.value) {
        return;
      }
      const resultCheckResponse = await checkResponse();
      const resultClaim = await claimQuizQuest(selectedResponse.value)
      if (resultCheckResponse) {
        responseIsCorrect.value = true;
      }
      if (!resultCheckResponse) {
        responseIsCorrect.value = false;
      }
      responseIsSubmited.value = true;
    };

    const checkResponse = async () => {
      const url_base = useRuntimeConfig().public.apiLogicUrl;
      const response = await $fetch(`${url_base}/claims/quiz/checkquiz`, {
        method: "POST",
        body: JSON.stringify({
          quiz_id: modalsStore.modalPayload.quest.values[0].quiz_id,
          selected_response: selectedResponse.value,
          bonus_id: modalsStore.modalPayload.quest.id,
          userid: authStore.user.id,
        }),
        credentials: "include",
      });

      return response;
    };

    async function claimQuizQuest(selectedResponse) {
      const apiLogicUrl = useRuntimeConfig().public.apiLogicUrl;
      const response = await $fetch(`${apiLogicUrl}/claims/quiz/claim-quiz`, {
        method: "POST",
        body: JSON.stringify({
          quiz_id: modalsStore.modalPayload.quest.values[0].quiz_id,
          selected_response: selectedResponse,
          bonus_id: modalsStore.modalPayload.quest.id,
        }),
        credentials: "include",
      })
        .then(() => {
          cms.loadUserLogs().then();
          cms.loadNotifications().then();
          cms.loadBonusQuests().then();
          cms.loadBonusQuests().then(cms.loadCompletedBonusQuests)
        })
        
      return response;
    }

    function reloadPageAndOpenLink() {
      window.open(selectedQuiz.value.link, "_blank");
      location.reload();
    }

    onMounted(() => {
      loadQuiz();
    });

    return {
      selectedQuiz,
      selectResponse,
      selectedResponse,
      responseIsSubmited,
      reloadPageAndOpenLink,
      claimQuizQuest,
      submitResponse,
      responseIsCorrect,
    };
  },
};
</script>


