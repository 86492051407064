<!-- ClaimYourBadge.vue -->
<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="leading-none text-center">
      There seems to be problems with your session
    </Typography>
    <Typography class="text-center">
      Please close session and try again.
    </Typography>
    <button
      @click="logout"
      class="bg-primary text-white px-8 rounded mx-auto flex items-center gap-4"
    >
      <Typography size="main-l">Logout</Typography>
    </button>
  </div>
</template>

<script setup>
import Typography from "@/components/common/Typography.vue";
import { useAuthStore } from "~/stores/useAuthStore";

function logout (){
    const auth = useAuthStore();
    auth.logout();
}


</script>
    