<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">Welcome to Albarra!</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center">Congrats on earning your first logs! You can now visit "Woods" to complete quests, "Tomahawk" to axe around, "Cliff" to see our parents and "Watch Post" for a bird's-eye view of the Albarra village</Typography>
    </span>
    <button class="bg-primary text-white px-20 py-2 rounded mx-auto flex items-center gap-4"
            @click="closeModal">
      <Typography size="main-l"> Explore </Typography>
    </button>
  </div>
</template>

<script setup>
import Typography from "@/components/common/Typography.vue"
import { useModalsStore } from '../../stores/useModalsStore'
import { useAuthStore } from '../../stores/useAuthStore'

const authStore = useAuthStore()
const modalsStore = useModalsStore()

const closeModal = () => {
  modalsStore.closeModal()
}
</script>
  
<style scoped>
</style>
  