<template>
  <div class="flex flex-col gap-6 justify-items-center items-center">
    <img src="https://albarra.world/cms/assets/df20173e-3852-45bc-8a22-d60579ae8501" alt="" style="max-width: 350px;margin: 0% auto;">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">The Rail</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <span class="flex flex-col gap-2">
      <Typography size="secondary-book" class="text-center">Thank you for downloding the first Lokie Labs UX tool that will upgrade your everyday degen tasks. Claim your rewards!</Typography>
    </span>
    <Button @click="onClickClaim"
            class="h-16 px-10 bg-primary font-bold text-2xl flex items-center justify-center gap-4">
      <span style="font-size: 40pt; font-family: Ravenholm;font-weight: normal !important;">Claim Badge + 420</span> <img src="~/assets/svg/troncos.svg" class="w-6 h-6" />
    </Button>
  </div>
</template>
  
<script setup>
import Typography from "@/components/common/Typography.vue"
import { cmsStore } from "../../stores/cmsStore";
import { useModalsStore } from '../../stores/useModalsStore'

const modalsStore = useModalsStore()
const cms = cmsStore();

const onClickClaim = () => {
  const THE_RAIL_BADGE_ID = 49
  cms.userHasBadge(THE_RAIL_BADGE_ID)
     .then(response => {
        console.log(response)
        if (response.code === 'success-badge-assigned-now') {
          modalsStore.closeModal()
          window.location.href = '/badges'
        }
     })
}

const closeModal = () => {
  modalsStore.closeModal()
  navigateTo('/badges')
}
</script>
  
<style scoped>
</style>
  