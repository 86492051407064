<template>
  <div class="flex flex-col gap-6">
    <Typography size="main-xl" class="text-center leading-none text-nowrap">Link Other Page</Typography>
    <div class="w-1/6 h-[0.25rem] bg-red-500 mx-auto"></div>
    <Typography size="secondary-book" class="mt-5 text-center">Copy & paste the wallet address linked to your Other Page, your profile for the metaverse</Typography>
    <input v-model="walletAddress" type="text" placeholder="Wallet Address" class="input-field border px-4 py-2 rounded">
    <div v-if="error" class="text-red-500 text-center">{{ error }}</div>
    <button class="mt-10 bg-primary text-white px-20 py-2 rounded mx-auto flex items-center gap-4"
            @click="submitWalletAddress">
      <Typography size="main-l"> Link </Typography>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useFetch } from '#app'
import { useAuthStore } from '../../stores/useAuthStore'
import { useModalsStore } from '../../stores/useModalsStore'
import Typography from "@/components/common/Typography.vue";

const auth = useAuthStore()
const modal = useModalsStore()
const walletAddress = ref('')
const error = ref('')

const validateWallet = (address) => /^0x[a-fA-F0-9]{40}$/.test(address)

const submitWalletAddress = async () => {
  if (!walletAddress.value) {
    error.value = "Wallet address is required"
    return
  }
  if (!validateWallet(walletAddress.value)) {
    error.value = "Invalid wallet address"
    return
  }
  try {
    const userId = auth.user.directusUser.id
    const { data, error } = await useFetch(`/api/user/${userId}/other_page`, {
      method: 'PUT',
      body: { otherPage: walletAddress.value },
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (error.value) {
      throw new Error(error.message)
    }
    const userPayload = data.value.data
    error.value = ''
    auth.updateUserOtherPage(userPayload.other_page)
    modal.closeModal()
  } catch (err) {
    console.error('Error updating wallet address:', err)
    error.value = err.message || 'Failed to update wallet address'
  }
}
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 320px;
}

.input-field {
  width: 100%;
  color: black;
}
</style>
